@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
:root{
--black: #121212;
--black1: #121212d0;
--white: #FDEFF4;
--yellow:  #F9AA19;
--red: #DD4A48;
--white1: #fdeff469;
--orange: #FF8225;
--brown: #76453B;
--gray: #B3AEAD;
--blue:  #070675;
--pink: #F8C8DC;

}

body{
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background-color: var(--black);
}

/* Swiper-specific styling */
.swiper-button-prev, .swiper-button-next {
  background-color: var(--yellow); /* Change button color */
  border-radius: 50%; /* Make buttons round */
  padding: 10px; /* Adjust padding */
  width: 40px;
  height: 40px;
  color: white;
}

/* Space between the text and Swiper buttons */
.slide-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Add margin or gap for spacing */
.slide-content p {
  margin-right: 20px; /* Adjust the spacing between text and buttons */
}

/* Basic styling for slick dots */
.slick-dots {
  margin-top: 80px; /* Adjust position */
  color: #FDEFF4;
}

.slick-dots li {
  width: 8px; /* Adjust size */
  height: 8px; /* Adjust size */
  color: #FDEFF4;
}

.slick-dots li button {
  background-color: #FDEFF4; /* Default color for inactive dots */
  border-radius: 50%; /* Make dots round */
  width: 5px; /* Adjust size */
  height: 5px; /* Adjust size */
}

.slick-dots li.slick-active button {
  background-color: var(--white); /* Color for active dot */
}

.rotating-container {
  position: relative;
  width: 300px; /* Adjust based on the desired circle size */
  height: 300px; /* Adjust based on the desired circle size */
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate-container 20s infinite linear;
}

/* Individual rotating items */
.rotating-item {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: rotate-item 20s infinite linear;
}

.rotating-item img {
  width: 50px; /* Adjust image size */
  height: 50px; /* Adjust image size */
}

@keyframes rotate-container {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.coming-soon {
  text-align: center;
  margin-top: 20%;
  font-family: Arial, sans-serif;
}

.coming-soon h1 {
  font-size: 2rem;
  color: #333;
}

.coming-soon p {
  font-size: 1rem;
  color: #666;
}


@keyframes rotate-item {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg); /* Counter rotation to keep the items upright */
  }
}


