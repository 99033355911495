.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
    border-top: 3px solid rgba(0, 0, 0, 0.1);
  }
  
  .footer-section {
    text-align: center;
  }
  
  .logo {
    max-height: 80px;
    cursor: pointer;
    margin-bottom: 15px;
    display: block; /* Ensures the logo behaves like a block-level element */
    margin-left: auto; /* Centers the logo horizontally */
    margin-right: auto; /* Centers the logo horizontally */
}
.contact-section{  
    margin-top: auto; /* Centers the logo horizontally */
    margin-bottom: auto; 
}

  
  .contact-info {
    color: #333;
    font-size: 0.9rem;
  }
  
  .footer-link {
    display: block;
    color: #555;
    text-decoration: none;
    font-size: 0.9rem;
    padding: 6px 0;
    transition: color 0.3s;
  }
  
  .footer-link:hover {
    color: #000;
  }
  
  .contact-button {
    width: 7.5rem;
    height: 2.5rem;
    background-color: #ffcc00; /* Replace with your yellow color */
    color: #000;
    border-radius: 1.4rem;
    margin-top: 15px;
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: auto; /* Centers the logo horizontally */
    margin-bottom: auto; 
  }
  
  .social-icon {
    color: #555;
    font-size: 1.5rem;
    transition: color 0.3s;
  }
  
  .social-icon:hover {
    color: #000;
  }
  
  /* Media query for smaller screens */
  /* @media (max-width: 768px) {
    .footer-section {
      flex: 1 1 100%;
      margin-bottom: 15px;
    }
  
    .contact-button {
      width: 100%;
      margin-top: 10px;
    }
  } */
  